'use strict'
const name = 'BaseRotate'
const properties = {}

const supportedDirections = {
    cw: true,
    ccw: true,
    short: true
}

function register({engine, factory}/*, frame*/) {
    /**
     * Rotate animation object
     * @param {Array<HTMLElement>|HTMLElement} elements DOM elements
     * @param {Number} [duration=1.0]
     * @param {Number} [delay=0]
     * @param {Object} params
     * @param {Number|String} [params.from.rotation]
     * @param {Number|String} [params.to.rotation]
     * @returns {TweenMax}
     */
    function animation(elements, duration = 0, delay = 0, params = {}) {
        const {to, from} = params
        if (to && typeof to.rotation !== 'undefined' && supportedDirections[to.direction]) {
            params.to.rotation = `${to.rotation}_${to.direction}`
        }
        if (from && typeof from.rotation !== 'undefined' && supportedDirections[from.direction]) {
            params.from.rotation = `${from.rotation}_${from.direction}`
        }

        return engine.tween(elements, {duration, delay, ...params}, ['rotation'])
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
