'use strict'
const name = 'HeaderMoveCustom'
const EASE_DEFAULT = 'Linear.easeNone'

const properties = {
    schema: {
        duration: {
            type: 'number',
            min: 0,
            default: 0
        },
        delay: {
            type: 'number',
            min: 0,
            default: 0
        }
    }
}

function register({factory}) {
    function animation(elements, duration, delay, {to = {}, ease = EASE_DEFAULT, ...params}) {
        const sequence = factory.sequence(params)

        sequence.add(factory.animate('BasePosition', elements, duration, delay, {to, ease}))

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
